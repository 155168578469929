@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;600;700;800&family=IBM+Plex+Sans:wght@400;600&display=swap');
/* Custom fonts */

@import "./assets/fontawesome/css/all.css";
/* fontawesome */

@import "./assets/keenthemes-icons/ki.css";
/* ki */

@import "./assets/flaticon2/flaticon.css";
/* flaticon2 */

@import "~perfect-scrollbar";
/* perfect scrollbar */

html, body {
  /* font size of root element (rem) */
  font-size: 14px !important;
}

body {
  margin: 0;
  /* font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Be Vietnam Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}