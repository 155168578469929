.SaymeeHeader {
  .nav-tabs {
    border-bottom: none;

    .nav-link.active,
    .nav-link:hover {
      border-bottom: 3px solid #e31d93 !important;
    }
  }
}
