.SaymeeMonthOrYearPicker {
  .dropdown-toggle {
    background-color: #ffffff !important;
    border: 1px solid #ebebeb;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: transparent !important;
    color: #e31d93 !important;
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    border-color: #ebebeb !important;
  }
  .btn:hover {
    border-color: #ebebeb !important;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    
    // box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background-color: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #EBEBEB;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    // background: #b30000;
  }
}
